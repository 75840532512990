@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: auto;
  src: url("Inter-Thin.woff2?v=3.18") format("woff2"),
       url("Inter-Thin.woff?v=3.18") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: auto;
  src: url("Inter-ExtraLight.woff2?v=3.18") format("woff2"),
       url("Inter-ExtraLight.woff?v=3.18") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: auto;
  src: url("Inter-Light.woff2?v=3.18") format("woff2"),
       url("Inter-Light.woff?v=3.18") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: auto;
  src: url("Inter-Regular.woff2?v=3.18") format("woff2"),
       url("Inter-Regular.woff?v=3.18") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: auto;
  src: url("Inter-Medium.woff2?v=3.18") format("woff2"),
       url("Inter-Medium.woff?v=3.18") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: auto;
  src: url("Inter-SemiBold.woff2?v=3.18") format("woff2"),
       url("Inter-SemiBold.woff?v=3.18") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: auto;
  src: url("Inter-Bold.woff2?v=3.18") format("woff2"),
       url("Inter-Bold.woff?v=3.18") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: auto;
  src: url("Inter-ExtraBold.woff2?v=3.18") format("woff2"),
       url("Inter-ExtraBold.woff?v=3.18") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: auto;
  src: url("Inter-Black.woff2?v=3.18") format("woff2"),
       url("Inter-Black.woff?v=3.18") format("woff");
}
/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: auto;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("Inter-roman.var.woff2?v=3.18") format("woff2");
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: auto;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("Inter-italic.var.woff2?v=3.18") format("woff2");
}
